
import Selector from '@/components/job/Selector.vue'
import JobCompare from '@/components/job/JobCompare.vue'
import Done from '@/components/job/Done.vue'
import JobType from '../components/job/JobType.vue'
import Images from '../components/job/Images.vue'
import { ContactVenue } from '@ht-vue/misc'
import Photographers from '../components/job/Photographers.vue'
import Notes from '../components/job/Notes.vue'
import { batchMergeImages } from '@ht-lib/image-util'
import {
  addLabUploaderToBooking,
  addLabUploaderToJob,
  BookingSnap,
  getAccountRef,
  getBookingRef,
  getEmptyJob,
  getJob,
  getJobTypeFromCode,
  imageEntriesFromJob,
  JobSnap,
  openJobAndBooking,
  Snapshot,
  updateBookingStudioJob,
  updateJob,
  updatePhotographerStatus,
  uploadBookingJobJson
} from '@ht-lib/accounts-common'
import { addImageGroupFromClass, JobFormSectionNames, makeJobFromUpload, SectionNames } from '../models/job'
import { ImageStore } from '@ht-vue/quality-control'
import {
  Account as CortexAccount,
  Booking,
  ImageEntry,
  Job,
  PartialDeep,
  PhotographerJobStatus,
  StudioUploadJob
} from '@ht-lib/accounts-models'
import { merge } from 'lodash'
import QStepper from 'quasar/src/components/stepper/QStepper.js';
import { Component, Prop, Ref } from 'vue-property-decorator'
import { getUploader } from '../uploader'
import type firebase from 'firebase/compat/app'
import Vue from '../VueBase'
import { labUploadDataProvider } from '../bookings'

interface StepperNavButton {
  label: string
  action: () => Promise<void>
  disabled?: boolean
}

interface StepperNav {
  back: StepperNavButton
  next: StepperNavButton
}

@Component({
  name: 'JobForm',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  components: { Selector, JobCompare, JobType, Images, ContactVenue, Photographers, Notes, Done }
})

export default class extends Vue {
  @Ref() jobStepper!: QStepper
  @Prop({ type: String, required: true }) bookingId!: string
  @Prop({ type: String, required: false }) jobId?: string
  @Prop({ type: Boolean, required: false, default: false }) isLabUploader!: boolean

  stepNames: SectionNames[] = ['selector', 'jobCompare', 'jobType', 'images', 'contactVenue', 'termDates', 'photographers', 'note', 'done']
  jobFormSectionNames: JobFormSectionNames[] = ['jobCompare', 'jobType', 'contactVenue', 'termDates', 'photographers', 'note']

  bookingSnap: BookingSnap = null
  account: CortexAccount = null
  job: Job | null = null
  jobSnap!: JobSnap
  uploadJob: StudioUploadJob | null = null
  imageEntries: ImageEntry[] | null = null

  step = 0
  dataLoaded = false
  canContinue = false

  loadingNext = false
  jobType: string | null = null

  snapshots: { [id: string]: Snapshot } = {}

  get booking (): Booking {
    return this.bookingSnap.data()
  }

  get isNewBooking (): boolean {
    return !this.jobId
  }

  get showContactStep (): boolean {
    return this.job?.market === 'G'
  }

  get labUploaderId (): string | undefined {
    return this.isLabUploader ? this.$auth.user.uid : undefined
  }

  get uploaderId (): string {
    // uploaderId is used in a lot of places to refer back to photographersMap, so it needs to be the photographerId
    return this.isLabUploader ? labUploadDataProvider.getPhotographer().id : this.$auth.user.uid
  }

  get stepperNav (): StepperNav {
    const nextAction = async (): Promise<void> => {
      if (this.step >= this.stepIndex('jobType')) {
        await this.updateDocuments()
      }

      if (this.step < this.stepIndex('done')) {
        this.jobStepper.next()
      }
    }

    let back: StepperNavButton = {
      label: 'Back',
      action: async () => {
        await Promise.resolve(this.jobStepper.previous())
      }
    }

    let next: StepperNavButton = {
      label: 'Continue',
      action: nextAction
    }

    switch (this.stepNames[this.step]) {
      case 'jobType': {
        next = {
          label: 'Start Upload',
          action: async (): Promise<void> => {
            await nextAction()
            await this.startUploadSequnce()
          }
        }
        break
      }
      case 'images':
        next = {
          label: 'Continue',
          action: async (): Promise<void> => {
            await ImageStore.Instance.save()
            await nextAction()
          }
        }
        break
      case 'done':
        next = {
          label: 'Finish',
          action: async (): Promise<void> => {
            try {
              const status = await openJobAndBooking(this.bookingId, this.jobSnap.ref, this.$auth.user.uid)
              if (status !== undefined) {
                this.updateJob({ status })
              }
            } catch (e) {
              console.error('Error (re)opening Job/Booking on the last stage of the Job Stepper')
              throw e
            } finally {
              await nextAction()
              await this.$router.push('/')
            }
          }
        }
        break
      case 'jobCompare':
        back = {
          label: 'Pick another Booking',
          action: async (): Promise<void> => {
            await this.$router.push('/')
          }
        }
        next = {
          label: 'Continue',
          action: async (): Promise<void> => {
            await nextAction()

            if (this.isLabUploader) {
              this.updateJobType({
                jobType: labUploadDataProvider.getJobType()
              })

              await this.stepperNav.next.action()
            }
          }
        }
    }
    return { back, next }
  }

  async next (): Promise<void> {
    if (this.loadingNext) return
    this.loadingNext = true
    await this.stepperNav.next.action()
    this.loadingNext = false
  }

  async created (): Promise<void> {
    console.log('bookingId', this.bookingId)
    await this.setupSnapShot(
      'book',
      getBookingRef(this.bookingId),
      (b) => {
        console.log('Booking updated', b.data())
        this.bookingSnap = b
      }
    )
    await this.setupSnapShot(
      'account',
      getAccountRef(this.booking.accountId),
      (a) => {
        console.log('Account updated', a.data())
        this.account = a.data()
      }
    )
    console.log('booking:accountId', this.booking.accountId)
    console.log('jobId', this.jobId)
    await this.changeJob(this.jobId)
    this.dataLoaded = true
  }

  async changeJob (jobId?: string): Promise<void> {
    if (jobId) {
      console.log('Changing job to jobId: ', jobId)
      this.jobSnap = await getJob(jobId)
    } else {
      console.log('Changing job NEW job ')
      this.jobSnap = await getEmptyJob()
    }

    await this.setupSnapShot('job', this.jobSnap.ref, (jobSnap) => {
      this.jobSnap = jobSnap
      console.log('Job Snapshot updated from:', this.job, 'TO:', this.jobSnap.data())
      this.job = this.jobSnap.data() ?? null
    })
  }

  async setupSnapShot<T> (id: string, ref: firebase.firestore.DocumentReference<T>, cb: (d: firebase.firestore.DocumentSnapshot<T>) => void): Promise<void> {
    if (this.snapshots[id] != null) {
      this.snapshots[id]()
    }

    return new Promise((resolve) => {
      this.snapshots[id] = ref.onSnapshot(snap => {
        cb(snap)
        resolve()
      })
    })
  }

  beforeDestroy (): void {
    Object.values(this.snapshots).forEach(x => x())
  }

  stepIndex (name: SectionNames): number {
    return this.stepNames.indexOf(name)
  }

  stepDone (name: SectionNames): boolean {
    return this.step > this.stepIndex(name)
  }

  updateJobType (job: PartialDeep<Job>): void {
    this.jobType = job.jobType || ''
    this.updateJob(job)
  }

  updateJob (update: PartialDeep<Job>): void {
    if (this.job != null) {
      this.job = merge(this.job, update)
    }
  }

  async marshallJobData (): Promise<void> {
    if (this.uploadJob == null) {
      throw Error('Cannot create firestore job because upload job is not defined')
    }

    if (this.booking == null) {
      throw Error('Cannot create firestore job because booking is not defined')
    }

    if (this.account == null) {
      throw Error('Cannot create firestore job because account is not defined')
    }

    if (this.job == null) {
      this.job = await makeJobFromUpload(this.uploadJob, this.bookingSnap, this.account, this.jobType || this.booking.jobType)
    }
  }

  async addImageGroups (): Promise<void> {
    if (this.job.market === 'G') {
      await Promise.all(this.uploadJob.studio.job.classes.map(async c => addImageGroupFromClass(c, this.$auth.user.uid)))
    }
    console.log('Skipping adding image groups. Job is not a grad job')
  }

  async startUploadSequnce (): Promise<void> {
    this.toggleContinue(false)
    this.imageEntries = await imageEntriesFromJob(this.uploadJob, this.account, this.booking.accountId, this.bookingId, this.uploaderId, this.jobId ?? this.jobSnap.id, this.job, this.labUploaderId)

    // Images must be in firestore before thumbnail generator runs
    await batchMergeImages(this.imageEntries)

    const uid = this.isLabUploader ? this.uploaderId : null
    const promises = [
      this.startImageUpload(),
      this.addImageGroups(),
      updatePhotographerStatus(this.jobSnap, PhotographerJobStatus.UPLOADING, uid)
    ]

    if (this.isLabUploader) {
      promises.push(addLabUploaderToJob(this.jobSnap.ref, this.labUploaderId))
      promises.push(addLabUploaderToBooking(this.bookingSnap.ref, this.labUploaderId))
    }

    await Promise.all(promises)
    this.toggleContinue(true)
  }

  async startImageUpload (): Promise<void> {
    const type = await getJobTypeFromCode(this.job.jobType)
    getUploader().addUploadJob({
      description: `${ this.booking.accountName } - ${ type.description }`,
      id: this.jobSnap.id,
      uploaderId: this.uploaderId,
      meta: {
        studioJobId: this.uploadJob.studio.uid,
        job: this.job
      },
      images: this.imageEntries.map(x => {
        return {
          entry: x,
          longRef: x.longRef,
          file: this.uploadJob.images.find(s => s.image.longRef === x.longRef).file,
          uploaded: false
        }
      })
    })
  }

  toggleContinue (b: boolean): void {
    this.canContinue = b
  }

  async jobSelected (job: StudioUploadJob): Promise<void> {
    this.uploadJob = job
    await this.marshallJobData()
    this.step++
  }

  private async updateDocuments (): Promise<void> {
    const updates = [updateJob(this.jobSnap.id, this.job)]

    const job = this.uploadJob.studio.job
    const uid = this.uploadJob.studio.uid
    if (this.uploadJob != null) {
      updates.push(
        updateBookingStudioJob(uid, {
          bookingId: this.bookingId,
          accountCode: this.uploadJob.studio.code,
          accountName: this.uploadJob.studio.name,
          uploaderId: this.$auth.user.uid,
          imageCount: this.uploadJob.images.length,
          jobTypeId: job.jobTypeid,
          jobTypeDescription: job.jobTypeDescription,
          styleId: job.styleId,
          styleDescription: job.styleDescription,
          takeDate: job.date,
          usedNameSearch: job.usedNameSearch || false,
          dataLinked: job.dataLinked || false
        }),
        uploadBookingJobJson(uid, this.uploadJob.jobJson)
      )
    }

    await Promise.all(updates)
  }
}
