
import { filterFiles } from '@/models/filesystem'
import { Component, Prop, Ref, Vue } from 'vue-property-decorator'
import { getUploader } from '../../uploader'
import { Image, ImageWithData, Job } from '@ht-web/firebase-adapter'
import Notify from 'quasar/src/plugins/Notify.js';
import { JobSnap } from '@ht-lib/accounts-common'
import { ImageEntry, Job as JobModel, StudioUploadJob } from '@ht-lib/accounts-models'
import { labUploadDataProvider } from '../../bookings'
import { tryCreateUploadJob } from '../../models/UploadJob'
import _ from 'lodash'

interface ImageCountWithJob {
  job: JobModel | undefined
  numberOfImages: number
}

interface AccountToImageCountWithJob {
  [accountCode: string]: ImageCountWithJob
}

@Component({
  name: 'Selector'
})
export default class extends Vue {
  @Prop({ type: Object, required: true }) job: JobSnap
  @Prop({ type: Boolean, required: false, default: false }) isLabUploader!: boolean

  @Ref() selectJobInput!: HTMLInputElement
  uploader = getUploader()
  studioJob: StudioUploadJob = null
  alreadyUploaded: ImageEntry[] = null
  inAnotherJob: AccountToImageCountWithJob = null
  inNexus: AccountToImageCountWithJob = null

  get isAlreadyUploaded (): boolean {
    return this.alreadyUploaded?.length > 0
  }

  async mounted (): Promise<void> {
    if (this.isLabUploader) {
      const existingFiles = labUploadDataProvider.getFiles()
      if (existingFiles != null) {
        await this.inputFiles(existingFiles)
      }
    }
  }

  async inputFiles (fileList: FileList): Promise<StudioUploadJob | undefined> {
    const files = filterFiles(Array.from(fileList))
    const result = await tryCreateUploadJob(files)

    if (result.errors.length > 0) {
      result.errors.forEach(x => {
        Notify.create({
          message: x,
          timeout: 0,
          actions: [{
            label: 'Dismiss',
            color: 'white',
            handler: (): void => {
              console.log('dismissed')
            }
          }],
          type: 'negative'
        })
      })
    }

    if (result.job === undefined) {
      return undefined
    }

    this.studioJob = result.job

    let images = await Promise.all(result.job.images.map(async x => Image.byId(x.image.longRef).singleOrNull()))
    images = images.filter(x => x != null)

    const devOverride = process.env.VUE_APP_ALLOW_UPLOAD_OVERRIDE === 'true'
    if (this.job.exists && ((this.job.data().allowUploadOverride ?? false) || devOverride)) {
      console.log('Admin Override (allowUploadOverride) is true')
    } else {
      const inAnotherJob = images.filter(x => x.jobId !== this.job.id)
      const inNexus = images.filter(x => x.nexusId > 0)
      this.alreadyUploaded = [...inAnotherJob, ...inNexus]

      const r = await Promise.all(
        _(this.alreadyUploaded)
          .uniqBy(x => x.jobId)
          .map(async x => {
            return {
              jobId: x.jobId,
              job: await Job.byId(x.jobId).singleOrNull()
            }
          })
          .value()
      )

      const reducer = (acc: AccountToImageCountWithJob, current: ImageWithData): AccountToImageCountWithJob => {
        const account = acc[current.accountCode] ??= {
          job: r.find(x => x.jobId === current.jobId)?.job,
          numberOfImages: 0
        }

        account.numberOfImages += 1
        return acc
      }

      this.inAnotherJob = inAnotherJob.reduce(reducer, {})
      this.inNexus = inNexus.reduce(reducer, {})

      if (this.alreadyUploaded.length > 0) {
        console.log('Already Uploaded images: ', this.alreadyUploaded)
        return undefined
      }
    }

    this.$emit('job-selected', this.studioJob)
    return undefined
  }

  accountWithJobCode (accountCode: string, icwj: ImageCountWithJob) {
    let accountName: string | null = null
    let shortText = accountCode
    if (icwj.job != null) {
      accountName = icwj.job.account.name
      if (icwj.job.aimsJobNumber != null && icwj.job.aimsJobNumber !== '') {
        shortText += `-${ icwj.job.aimsJobNumber }`
      }
    }

    return accountName != null
      ? `${ accountName } (${ shortText })`
      : shortText
  }

  selectJobButtonClicked (): void {
    this.selectJobInput.value = ''
    this.selectJobInput.click()
  }

  goBack () {
    window.history.back()
  }
}
