
import { Component, Vue } from 'vue-property-decorator'

@Component({
  name: 'Done',
  components: {}
})
export default class extends Vue {

}
